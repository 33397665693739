/* eslint-disable dot-notation */
import { useState, useEffect } from 'react';

import CookiesService from '@mshops-web-core/cookies';

import { DEVICES_INFO, WEBVIEW_URL } from './const';
import { useRenderContext } from '../../pages/home/context';

const useCheckoutRedirect = (productCartInfo) => {
  const [urlObjectToCheckout, setUrlObjectToCheckout] = useState(productCartInfo);
  const { csrfToken } = useRenderContext();

  const getDeviceInfo = device => {
    const { userAgent } = window.navigator;
    const match = userAgent.match(device.regex);
    return match && {
      os: device.os,
      version: match[1],
      minimumVersion: device.minimumVersion,
    };
  };

  const decorateUrl = (urlObj) => {
    const deeplinkParameters = {
      authentication_mode: 'required',
      search_mode: 'none',
      back_style: 'none',
      back_action: 'close',
      bar_left_button_style: 'none',
      hides_bottom_bar: true,
      use_web_title: false,
      'webkit-engine': 2,
    };

    const dlQueryParams = Object.entries(deeplinkParameters)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const { protocol, hostname, pathname, search } = urlObj;
    const encodedUrl = encodeURIComponent(`${protocol}//${hostname}${pathname}${search}`);

    return `${WEBVIEW_URL}${encodedUrl}&${dlQueryParams}`;
  };

  const calculateDecoratorNeeds = (urlObj) => {
    const deviceInfo = getDeviceInfo(DEVICES_INFO.android) || getDeviceInfo(DEVICES_INFO.ios);
    if (!deviceInfo) {
      return urlObj.toString();
    }

    const isEligible = parseInt(deviceInfo.version, 10) >= deviceInfo.minimumVersion;

    if (isEligible) {
      return decorateUrl(urlObj);
    }

    return urlObj.toString();
  };

  const addQueryParamsToUrl = (url) => {
    const urlObj = new URL(url);
    const queryParams = {};
    queryParams.mshops_d2id = CookiesService.getCookie('_d2id');
    queryParams['_d2id'] = CookiesService.getCookie('_d2id');
    const mshopsHaCIdCookie = CookiesService.getCookie('_mshops_ga_cid');
    if (mshopsHaCIdCookie) {
      queryParams['_mshops_ga_cid'] = mshopsHaCIdCookie;
    }
    queryParams['_csrf'] = csrfToken;
    queryParams.mshops_mldataSessionId = CookiesService.getCookie('_mldataSessionId_mshops');

    Object.keys(queryParams).forEach(key => {
      const value = queryParams[key];
      urlObj.searchParams.append(key, value);
    });

    setUrlObjectToCheckout({
      ...urlObjectToCheckout,
      checkout_target_mobile: calculateDecoratorNeeds(urlObj),
      checkout_target: urlObj.toString(),
    });
  };

  useEffect(() => {
    if (productCartInfo && !productCartInfo.is_cart_eligible) {
      addQueryParamsToUrl(productCartInfo.checkout_target);
    }
  }, [productCartInfo]);

  return urlObjectToCheckout;
};

export default useCheckoutRedirect;
