const WEBVIEW_URL = 'meli://webview/?url=';
const ANDROID_OS = 'android';
const ANDROID_VERSION_REGEX = /Android (\d+(?:\.\d+){0,2})/;
const ANDROID_MINIMUM_SUPPORTED_VERSION = 6;
const IOS_OS = 'ios';
const IOS_VERSION_REGEX = /OS ((\d+_?){2,3})\s/;
const IOS_MINIMUM_SUPPORTED_VERSION = 13;

const DEVICES_INFO = {
  android: {
    os: ANDROID_OS,
    regex: ANDROID_VERSION_REGEX,
    minimumVersion: ANDROID_MINIMUM_SUPPORTED_VERSION,
  },
  ios: {
    os: IOS_OS,
    regex: IOS_VERSION_REGEX,
    minimumVersion: IOS_MINIMUM_SUPPORTED_VERSION,
  },
};

const DECORATE_STATES = {
  INIT: 'init',
  DECORATE: 'DECORATE',
  DO_NOT_DECORATE: 'DO_NOT_DECORATE',
};

export { DECORATE_STATES, WEBVIEW_URL, DEVICES_INFO };
